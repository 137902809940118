
































import { defineComponent, reactive } from "@vue/composition-api";
import voucher_codes from "voucher-code-generator";

export default defineComponent({
  setup(_, { emit }) {
    const model = reactive({
      count: 100,
      length: 10,
      prefix: "promo-",
    });

    const state = reactive({
      codes: [] as string[],
    });

    const generateCodes = () => {
      state.codes = voucher_codes.generate({
        length: model.length,
        count: model.count,
        prefix: model.prefix,
      });

      emit("close");
      emit("getCodes", state.codes);
    };
    return { model, generateCodes };
  },
});
